import './navigation-drawer'
import './navigation-dropdown'
import './cookies'

const chevronDownIcon = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 4.5L6 7.5L9 4.5" stroke="#94A3B8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

document.querySelectorAll('.link-item .arrow-down').forEach((el) => {
  el.innerHTML += chevronDownIcon
})

const hamburgerIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 12H21M3 6H21M3 18H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

document.querySelector('.btn-hamburger').innerHTML += hamburgerIcon
